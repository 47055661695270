import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';


const config = {
    projectId: import.meta.env.VITE_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2021-03-25', // use current UTC date - see "specifying API version"!
    token: import.meta.env.VITE_SANITY_TOKEN, // or leave blank for unauthenticated usage
    useCdn: false, // `false` if you want to ensure fresh data
};


export const client = sanityClient(config);
export const imageBuilder = imageUrlBuilder(config);


// eslint-disable-next-line no-unused-vars
export const urlFor  = function(source) {
    return imageBuilder.image(source)
}
